// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()
require('jquery')

// document.addEventListener("turbolinks:load", function () {
//   $(function(){
//     $('.infiniteslide').infiniteslide({
//       'speed': 100, //速さ　単位はpx/秒です。
//       'direction': 'up', //up/down/left/rightから選択
//       'pauseonhover': false, //マウスオーバーでストップ
//       'responsive': true, //子要素の幅を%で指定しているとき
//       'clone': 6 //子要素の複製回数
//     });
//   });
// });

$(window).on('load scroll', function (){

  var box = $('.slide_box');
  var slide = 'slide';

  box.each(function(){

    var boxOffset = $(this).offset().top;
    var scrollPos = $(window).scrollTop();
    var wh = $(window).height();

    //画面内のどの位置で処理を実行するかで「100」の値を変更
    if(scrollPos > boxOffset - wh + 100 ){
      $(this).addClass(slide);
    }
  });

});
